<template>
  <!--  首页vue-->
  <div :class="isBrights ? 'bright' : 'dark'">
    <div class="blank15"></div>
    <el-drawer title="我的记事本" :visible.sync="myNote" direction="rtl" :before-close="handleClose">
      <el-input style="margin: 8px" type="textarea"
                :rows="25" v-model="userInfo.note"></el-input>
    </el-drawer>
    <el-row :gutter="30">
      <el-col :span=24>
        <el-tooltip class="item" effect="light" content="切换主题" placement="left" style="float:left; margin-left: 15px;margin-top: 20px">
          <el-button icon="el-icon-brush" circle @click="changebg"></el-button>
        </el-tooltip>
        <div style="float:right; margin-right: 15px">
          <el-tooltip class="item" effect="light" content="我的记事本" style="display: block">
            <el-button icon="el-icon-notebook-2" circle @click="myNote = true"></el-button>
          </el-tooltip>
        </div>
      </el-col>
    </el-row>
<el-row style="margin-top: 10px">
  <el-col :span="24">
    <div style="float:right; margin-right: 15px">
      <el-button style="display: block" icon="el-icon-s-promotion" circle @click="baiduMap"></el-button>
    </div>
  </el-col>
</el-row>

    <!--    大div框-->
    <div style="display: flex ;flex-wrap: nowrap;flex-direction: row;width: 100%;">

      <!--      左侧div-->
      <div style="width: 35%;margin-left: 2%">

        <h1 style="align-content: center;" :class="isBrights?'bg':'bgb'">共享文档</h1>

        <div style="width: 100% ;display: flex;flex-wrap: nowrap;flex-direction: row">

          <!--          左侧六边形-->
          <div style="width: 50%;margin-top: 30px;display: flex;justify-content: center;flex-direction: column;height: 100%;">

            <div class="box_left" >
              <div class="box_div" >
                <p class="box_p font"><router-link to="/filesroot/weekPlan">专业<br>计划</router-link></p>
              </div>
              <div class="box_div">
                <p class="box_p font"><router-link to="/filesroot/personalFiles">日常<br>计划</router-link></p>
              </div>
            </div>
            <div class="box_right" >
              <div class="box_divtowrow">
                <p class="box_p font"><router-link to="/filesroot/water">上水<br>通知</router-link></p>
              </div>
              <div class="box_divtowrow">
                <p class="box_p font"><router-link to="/filesroot/rate">进度</router-link></p>

              </div>
            </div>
            <div class="box_left" >
              <div class="box_divthreerow">
                <p class="box_p font"><router-link to="/filesroot/buy">购销<br>合同</router-link></p>
              </div>
              <div class="box_divthreerow">
                <p class="box_p font"><router-link to="/filesroot/project">工程<br>合同</router-link></p>
              </div>
            </div>
            <div class="box_right" >
              <div class="box_divtfourrow">
                <p class="box_p font"><router-link to="/filesroot/ask">监管<br>要求</router-link></p>
              </div>
              <div class="box_divtfourrow">
                <p class="box_p font"><router-link to="/filesroot/context">技术<br>交底</router-link></p>
              </div>
            </div>
            <div class="box_left" >
              <div class="box_divfiverow">
                <p class="box_p font"><router-link to="/filesroot/note">记录本</router-link></p>
              </div>
              <div class="box_divfiverow">
                <p class="box_p font"><router-link to="/filesroot/doss">档案<br>管理</router-link></p>
              </div>
            </div>

          </div>
          <!--          左侧竖着的那排按钮-->
          <div style="width: 20%;margin-top: 30px;margin-left: 10%">
            <button class="button font1 yinying" style="margin: 4px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"><a id="a0" target="_blank">公司文件预留按钮</a></button>
            <button  class="gradientButtonTow font1 yinying" style="margin: 4px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"><a id="a1"  target="_blank">公司文件预留按钮</a></button>
            <button class="gradientButtonThree font1 yinying" style="margin: 4px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"><a id="a2"  target="_blank">公司文件预留按钮</a></button>
            <button  class="gradientButtonFour font1 yinying" style="margin: 4px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"><a id="a3"  target="_blank">公司文件预留按钮</a></button>
            <button class="gradientButtonFive font1 yinying" style="margin: 4px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"><a id="a4"  target="_blank">公司文件预留按钮</a></button>
            <button class="gradientButtonSix font1 yinying" style="margin: 4px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"><a id="a5"  target="_blank">公司文件预留按钮</a></button>
            <button  class="gradientButtonseven font1 yinying"   style="margin: 4px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"><a id="a6"  target="_blank">公司文件预留按钮</a></button>
            <button class="gradientButtonEigth font1 yinying" style="margin: 4px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"><a id="a7"  target="_blank">公司文件预留按钮</a></button>
            <button  class="gradientButton font1 yinying" style="margin: 4px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"><a id="a8"  target="_blank">公司文件预留按钮</a></button>
            <button class="gradientButtonNine font1 yinying" style="margin: 4px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"><a id="a9"  target="_blank">公司文件预留按钮</a></button>
            <button class="gradientButtonTen font1 yinying" style="margin: 4px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"><a id="a10" target="_blank">公司文件预留按钮</a></button>
          </div>

        </div>

      </div>

      <!--      中间div-->
      <div style="width: 25%;position:relative;display: flex;;flex-direction: column">
        <h1 style="align-content: center;" :class="isBrights?'bg':'bgb'">供暖期日常任务</h1>
        <div style="justify-content: center;display: flex;flex-direction: row">


          <div class="center-analyse">
            <div class="small" ><p class="clis" ><router-link to="/filesroot/day">每日能耗</router-link></p></div>
            <div class="small" @click="gotoLine"><p class="clis">运行参数历史记录</p></div>
            <div class="small" ><p class="weekcli"><router-link to="/filesroot/week">每周能耗统计</router-link></p></div>
            <div class="small" ><p class="weekcli"><router-link to="/filesroot/yue">每月能耗统计</router-link></p></div>
            <div class="fu"><p class="cliss"><router-link to="/filesroot/main">服务部总指标</router-link></p></div>
          </div>
        </div>
<!--        五个月份按钮-->
        <div style="margin-top: 15%;margin-left:10%;text-align: left"><p class="font" :class="isBrights?'bg':'bgb'">日能耗历史数据</p></div>
        <div style="height: 30px;width:80% ;margin-left:10%;display: flex;" >
          <div class="monthpic" >
            <router-link to="/filesroot/November"><p style="margin-top: 8%;margin-left:-10%;color: #FFFFFF" class="font">11月</p></router-link>
          </div>
          <div class="monthpic">
            <router-link to="/filesroot/December"><p style="margin-top: 8%;margin-left:-10%;color: #FFFFFF" class="font">12月</p></router-link>
          </div>
          <div class="monthpic">
            <router-link to="/filesroot/January"><p style="margin-top: 8%;margin-left:-10%;color: #FFFFFF" class="font">1月</p></router-link>
          </div>
          <div class="monthpic">
            <router-link to="/filesroot/February"><p style="margin-top: 8%;margin-left:-10%;color: #FFFFFF" class="font">2月</p></router-link>
          </div>
          <div class="monthpic">
            <router-link to="/filesroot/March"><p style="margin-top: 8%;margin-left:-10%;color: #FFFFFF" class="font">3月</p></router-link>
          </div>

        </div>
      </div>

      <!--      右侧div-->
      <div style="width: 32%;height: 100px;margin-right: 3%;margin-left: 3%">
        <h1 style="align-content: center;" :class="isBrights?'bg':'bgb'">重点工作</h1>

        <!--        右侧核心任务下面的四个div框，放置文件下载按钮-->
        <div
          style="width: 100%;display: flex;flex-direction: row;justify-content: center;flex-wrap: nowrap;margin-top: 30px">
          <div style="height: 400px;width: 25%;background: #9edfdf;margin: 8px;border-radius: 20px ;background: url(/img/rt_cost_bg.png)">
            <div
              style="height: 20%;width: 100%;display: flex;flex-direction: column;justify-content: center;border-radius: 20px">
              <h3 style="font-weight: bold">预算审批</h3>
            </div>

            <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto;max-height: 80%;overflow: auto;">
              <el-button class="yinying buttonbgc" style="margin: 8px;height: 55px;margin-top: 40px;"><router-link to="/filesroot/cost" class="font" >责任成本</router-link> </el-button>
              <el-button class="yinying buttonbgcone" style="margin: 8px;height: 55px"><router-link to="/filesroot/modification" class="font" >改造工程</router-link> </el-button>
              <el-button class="yinying buttonbgctow" style="margin: 8px;height: 55px"><router-link to="/filesroot/expand" class="font">扩建工程</router-link> </el-button>
            </ul>

          </div>


          <div style="height: 400px;width: 25%;background: #9edfdf;margin: 8px;border-radius: 20px;background: url(/img/rt_normal_bg.png)">
            <div
              style="height: 20%;width: 100%;display: flex;flex-direction: column;justify-content: center;border-radius: 20px">
              <h3 style="font-weight: bold">签字审批</h3>
            </div>



            <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto;max-height: 80%;overflow: auto;">
              <el-button class="font yinying buttonbgcthree" style="margin: 8px;min-width:110px"><router-link to="/filesroot/import">付款类</router-link></el-button>
              <el-button class="font yinying buttonbgcfour" style="margin: 8px;min-width:110px" ><router-link to="/filesroot/importone">工程类</router-link></el-button>
              <el-button class="font yinying buttonbgcfive" style="margin: 8px;min-width:110px"><router-link to="/filesroot/importtow">合同类</router-link></el-button>
              <el-button class="font yinying buttonbgcsix" style="margin: 8px;min-width:110px"><router-link to="/filesroot/importthree">新增类</router-link></el-button>
              <el-button  class="font yinying buttonbgcseven" style="margin: 8px;min-width:110px"><router-link to="/filesroot/importfour">资产类</router-link></el-button>
            </ul>
          </div>
          <div style="height: 400px;width: 25%;background: #9edfdf;margin: 8px;border-radius: 20px;background: url(/img/rt_private_bg.png)">
            <div
              style="height: 20%;width: 100%;;display: flex;flex-direction: column;justify-content: center;border-radius: 20px">
              <h3 style="font-weight: bold">个人文件</h3>
            </div>
            <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto;max-height: 78%">
              <el-button class="yinying bgcall" style="margin: 8px;max-width: 110px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" v-for="item in filenanmedata" :key="item.name"><a class="font1" :href="'https://' + item.url" target="_blank">{{item.name}}</a></el-button>
            </ul>
          </div>
        </div>
      </div>

    </div>


  </div>

</template>

<script>
import clientUtils from "../../js/clientUtils";
import bus from "../../js/bus";
export default {
  name: 'Desktop',
  data: function () {
    return {
      userInfo: null,
      isBrights: true,
      myNote: false,
      urlarr:[],
      desktopEditing: false,
      groupFiles: [],
      companyFiles: [],
      filenanmedata:[],
      one:{},
      tow:{},
      three:{},
      four:{},
      five:{},
      six:{},
      seven:{},
      eigth:{},
      nine:{},
      ten:{}
    }
  },
  methods: {
    handleClose: function () {
      const that =this;
      let updatejosn = JSON.stringify(this.userInfo);
      this.$confirm('确认关闭？')
        .then(() => {
          this.myNote = false
          clientUtils.post("/system/user/updateUserNode", updatejosn, function (res) {
            if (res!=null) {
              // 编辑用户信息成功
              // that.getPlan();
              // that.back();
              that.$message({
                message: '记事本保存成功',
                type: 'success'
              });
            }
          })
        });
    },
    changebg:function () {
      this.isBrights=!this.isBrights
      console.log(this.isBrights)
    },

    baiduMap:function () {
      window.open('https://map.baidu.com/@13741313.13,5104005.77,12z', '_blank');
    },
    Soncli(){
      this.isBrights = !this.isBrights;
      bus.$emit('son', this.isBrights)
      console.log(this.isBrights)
    },
    getDayFrom:function (){
      this.$router.push({name:'DayForm',params:{user:this.userInfo}})
    },

    getWeekFrom:function (){
      // this.$router.push({name:'WeekForm',params:{user:this.userInfo}})
    },
    getMonthFrom:function (){
      this.$router.push({name:'MonthForm',params:{user:this.userInfo}})
    },
    gotoLine:function (){
      this.$router.push({name:'line',params:{user:this.userInfo}})
    }

  },
  mounted() {
    console.log("dextop执行")
    this.$nextTick(() => {
      this.$emit('selectMenu', this.$route.path);
      this.userInfo = clientUtils.getLocalUserInfo();
      // 获取集团文件
      // 获取公司文件
      const that = this
      clientUtils.get('/system/ihsFile/selectPage?current=1'+ '&size=10&id=' + clientUtils.getLocalUserInfo().id+ '&type=' + "个人文件",function (res) {
        if (res.data!=null){
          that.filenanmedata =res.data.data.records
        }
      })
    })
    clientUtils.get('/system/ihsFile/selectPage?current=1'+ '&size=10&id=' + clientUtils.getLocalUserInfo().id+ '&type=' + "公司模板", function (res) {
      if (res.data != null) {
        for (let i = 0; i < 13 ; i++) {
          let a = document.querySelector("#a"+i)
          a.innerText = res.data.data.records[i].name
          a.href ='http://'+res.data.data.records[i].url ;
        }
      }
    })
  },

}
</script>

<style scoped>
.bright, .dark {
  background-size: cover;
  width: 100%;
  height: calc(100vh - 80px);
}

.bright {
  background-image: url("/img/desktop_bg.jpg");
  background-position: -5px 0;
}

.dark {
  background-image: url("/img/duty_bg.png");
  background-position: -5px 0;
}

.desktop-main {
  width: 90%;
  height: calc(100vh - 300px);
  min-height: 500px;
  margin: 100px 5% auto 5%;
  background-color: #5bc0de;
}

.bright .title h3 {
  color: black;
}

.dark .title h3 {
  color: white;
}


.center-analyse {
  margin-top: 40px;
  width: 90%;
  height: 270px;
  border-radius: 10px;
  text-align: center;
}



.orange {
  width: 90%;
  height: 80px;
  border-radius: 10px;
  margin-top: 20%;
  margin-left: 5%;
}

.small {
  width: 50%;
  height: 60%;
  float: left;
  background-color: #00CC66;
  border-radius: 30px;
  box-shadow: 0 0 8px 0 rgb(0 0 0);
}
.yinying{
  box-shadow: 0 0 8px 0 rgb(0 0 0);
}
.small:nth-child(1) {
  background-color: #FFCA28;
}

.small:nth-child(1):hover {
  background-color: #2fff;
}

.small:nth-child(2) {
  background-color: #4CAF50;
}

.small:nth-child(2):hover {
  background-color: #2fff;
}

.small:nth-child(3) {
  background-color: #38DFA7;
}

.small:nth-child(3):hover {
  background-color: #2fff;
}

.small:nth-child(4) {
  background-color: #3265BE;
}

.small:nth-child(4):hover {
  background-color: #2fff;
}

.fu {
  position: absolute;
  width: 45%;
  height: 130px;
  background-color: #FFFFCC;
  top: 280px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  box-shadow: 0 0 8px 0 rgb(0 0 0);
}

.fu:hover {
  background-color: #2fff;
}

.green {
  width: 90%;
  height: 50px;
  border-radius: 10px;
  margin-top: 20%;
  margin-left: 5%;
}



.clis {
  margin-top: 70px;
  font-size: 20px;
  font-weight: bold;
}
.font{
  font-size: 20px;
  font-weight: bold;
}
.font1{
  font-size: 13px;
  font-weight: bold;
}
.cliss {
  margin-top: 25%;
  font-size: 20px;
  font-weight: bold;
}

.weekcli {
  margin-top: 100px;
  font-size: 20px;
  font-weight: bold;
}


p {
  display: inline-block;
}


ul {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  /*width: 560px;*/
}

li {
  float: left;
  margin: 0 5px;
  height: 96px;
}

.hex {
  /*border: 2px solid;*/
  /*border-color: black;*/
  margin-right: 10px;
  overflow: hidden;
  display: block;
  width: 100px;
  height: 116px;
  transform: rotate(-60deg) skewY(30deg);
  border-radius: 5px;
}

.hex2 {

  margin: 0px 10px 10px 0px;
  overflow: hidden;
  display: block;
  width: 100px;
  height: 116px;
  transform: rotate(-60deg) skewY(30deg);
  border-radius: 5px;
}


.hex_box {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap
}

.hex_box2 {
  margin: 10px 0 0 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap
}

.hexIn {
  background-color: #ccc;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 116px;
  text-align: center;
  transform: skewY(-30deg) rotate(60deg);
}


.box_left{
  min-height: 115px;
  margin-right: 10%;
  display:flex;flex-direction:row-reverse;justify-content: center;
}

.box_right{
  margin-left: 10%;
  display:flex;flex-direction:row-reverse;justify-content: center;
}

.box_p{
  word-wrap: break-word;width: 100%;max-lines: 2;font-size: calc(100vw/110);text-align: center
}

.box_div{
  min-width: 100px;
  min-height: 115px;
  width: 35%;height: 40%;display: flex;flex-direction: column;background: url(/img/hex_orange.png) no-repeat ;background-size: cover;justify-content: center
}
.box_divtowrow{
  min-width: 100px;
  min-height: 115px;
  width: 35%;height: 40%;
  display: flex;flex-direction: column;background: url(/img/hex_yellow.png) no-repeat ;background-size: cover;justify-content: center
}
.box_divthreerow{
  min-width: 100px;
  min-height: 115px;
  width: 35%;height: 40%;
  display: flex;flex-direction: column;background: url(/img/hex_green.png) no-repeat ;background-size: cover;justify-content: center

}
.box_divtfourrow{
  min-width: 100px;
  min-height: 115px;
  width: 35%;height: 40%;
  display: flex;flex-direction: column;background: url(/img/hex_cyan.png) no-repeat ;background-size: cover;justify-content: center

}
.box_divfiverow{
  min-width: 100px;
  min-height: 115px;
  width: 35%;height: 40%;
  display: flex;flex-direction: column;background: url(/img/hex_blue.png) no-repeat ;background-size: cover;justify-content: center

}
.gradientButton{
  width: 150px;
  height:45px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  border-radius: 5px;
  background: linear-gradient(to bottom ,#26C6DA,#26C6DA,#26C6DA,#26C6DA);
  border-style: none;
}
.gradientButtonOne{
  width: 150px;
  height:45px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  border-radius: 5px;
  height:45px;
  background: linear-gradient(to bottom ,#6184CE,#537BCC,#4775CB,#3C6EC9,#3669C3);
  border-style: none;
}
.gradientButtonTow{
  width: 150px;
  height:45px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  border-radius: 5px;
  background: linear-gradient(to bottom, #FFA726,#FFA726,#FFA726,#FFA726);
  border-style: none;
}
.buttonbgcone{
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  border-radius: 5px;
  background: linear-gradient(to bottom, #FFA726,#FFA726,#FFA726,#FFA726);
  border-style: none;
}
.gradientButtonThree{
  width: 150px;
  height:45px;
  text-overflow: ellipsis;
  border-radius: 5px;
  overflow: hidden;
  max-lines: 2;
  background: linear-gradient(to bottom, #FFCA28,#FFCA28,#FFCA28,#FFCA28);
  border-style: none;
}
.buttonbgctow{
  text-overflow: ellipsis;
  border-radius: 5px;
  overflow: hidden;
  max-lines: 2;
  background: linear-gradient(to bottom, #FFCA28,#FFCA28,#FFCA28,#FFCA28);
  border-style: none;
}
.gradientButtonFour{
  width: 150px;
  height:45px;
  text-overflow: ellipsis;
  border-radius: 5px;
  overflow: hidden;
  max-lines: 2;
  background: linear-gradient(to bottom, #FFEE58,#FFEE58,#FFEE58,#FFEE58);
  border-style: none;
}
.buttonbgcthree{
  text-overflow: ellipsis;
  border-radius: 5px;
  overflow: hidden;
  max-lines: 2;
  background: linear-gradient(to bottom, #FFEE58,#FFEE58,#FFEE58,#FFEE58);
  border-style: none;
}
.gradientButtonFive{
  width: 150px;
  height:45px;
  border-radius: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  /*background: url(/img/img_4.png) center no-repeat;*/
  background: linear-gradient(to bottom ,#D4E157,#D4E157,#D4E157,#D4E157);
  border-style: none;
}
.buttonbgcfour{
  border-radius: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  /*background: url(/img/img_4.png) center no-repeat;*/
  background: linear-gradient(to bottom ,#D4E157,#D4E157,#D4E157,#D4E157);
  border-style: none;
}
.gradientButtonSix{
  width: 150px;
  height:45px;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 5px;
  max-lines: 2;
  /*background: url(/img/img_4.png) center no-repeat;*/
  background: linear-gradient(to bottom ,#9CCC65,#9CCC65,#9CCC65,#9CCC65);
  border-style: none;
}
.buttonbgcfive{
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 5px;
  max-lines: 2;
  /*background: url(/img/img_4.png) center no-repeat;*/
  background: linear-gradient(to bottom ,#9CCC65,#9CCC65,#9CCC65,#9CCC65);
  border-style: none;
}
.gradientButtonseven{
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  border-radius: 5px;
  width: 150px;
  height:45px;;
  /*background: url(/img/img_6.png) center no-repeat;*/
  background: linear-gradient(to bottom ,#66BB6A,#66BB6A,#66BB6A,#66BB6A);
  border-style: none;}
.gradientButtonEigth{
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  width: 150px;
  border-radius: 5px;
  height:45px;
  background: linear-gradient(to bottom ,#26A69A,#26A69A,#26A69A,#26A69A);
  /*background: url(/img/img_12.png) center no-repeat;*/
  border-style: none;
}
.buttonbgcsix{
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  border-radius: 5px;
  background: linear-gradient(to bottom ,#26A69A,#26A69A,#26A69A,#26A69A);
  /*background: url(/img/img_12.png) center no-repeat;*/
  border-style: none;
}
.gradientButtonNine{
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  width: 150px;
  height:45px;
  border-radius: 5px;
  background: linear-gradient(to bottom ,#29B6F6,#29B6F6,#29B6F6,#29B6F6);
  border-style: none;
}
.bgcall{
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  border-radius: 5px;
  /*background: url(/img/img_12.png) center no-repeat;*/
  border-style: none;
  background: linear-gradient(to bottom ,#29B6F6,#29B6F6,#29B6F6,#29B6F6);
}
.buttonbgcseven{
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  border-radius: 5px;
  background: linear-gradient(to bottom ,#26C6DA,#26C6DA,#26C6DA,#26C6DA);
  border-style: none;
}
.gradientButtonTen{
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  width: 150px;
  border-radius: 5px;
  height:45px;
  width: 150px;
  height:45px;
  background: linear-gradient(to bottom,#42A5F5,#42A5F5,#42A5F5,#42A5F5);
  border-style: none;
}
.gradientButtonTenOne{
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  width: 100px;
  border-radius: 5px;
  height:45px;
  width: 100px;
  height:45px;
  /*background: url(/img/img_9.png) center no-repeat;*/
  background: linear-gradient(to bottom,#16D581,#55ED9D,#55ED9C,#3FE093,#1BDF88,#1ADA85,#20E08B);
  border-style: none;
}
li:nth-child(9n +1) {
  /*margin-left: 60px;*/
}
.button{
  text-overflow: ellipsis;
overflow: hidden;
max-lines: 2;
  width: 150px;
  height:45px;
  border-radius: 5px;
  /*background: url(/img/img.png) center no-repeat;*/
  background: linear-gradient(to bottom, #FF7043,#FF7043,#FF7043,#FF7043);
  border-style: none;
}
.buttonbgc{
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  border-radius: 5px;
  /*background: url(/img/img.png) center no-repeat;*/
  background: linear-gradient(to bottom, #FF7043,#FF7043,#FF7043,#FF7043);
  border-style: none;
}
.monthpic{
  background: url(/img/pic.png) no-repeat;
  background-size: contain;
  height: 40px;
  width: 95px;
}
</style>
